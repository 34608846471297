






























































import { EventModel } from 'tradingmate-modules/src/models/api/events'
import { Component, Vue, Watch } from 'vue-property-decorator'
import ContactDetail from '@/components/layout/ContactDetail.vue'
import FacebookShare from '@/components/actions/FacebookShare.vue'
import { Services } from 'tradingmate-modules/src/services'
import Utils from 'tradingmate-modules/src/Utils'
import MapboxMap from '@/components/map/Map.vue'
import Gallery from '@/components/layout/Gallery.vue'

@Component({
  components: {
    ContactDetail,
    FacebookShare,
    MapboxMap,
    Gallery
  }
})
export default class EventDetails extends Vue {
  private eventId: string = this.$route.params.eventId
  private eventModel: EventModel | null = null

  private isoToDisplayDate = Utils.isoToDisplayDate
  private isoToDisplayTime = Utils.isoToDisplayTime

  // getTime (startDate: string, endDate: string): string {
  //   const startTime = this.isoToDisplayTime(startDate)
  //   const endTime = this.isoToDisplayTime(endDate)
  //   if (startTime === endTime) {
  //     return startTime
  //   } else { return `${startTime} - ${endTime}` }
  // }

  getDate (start: string, end: string): string {
    const startDate = this.isoToDisplayDate(start)
    const endDate = this.isoToDisplayDate(end)
    if (startDate === endDate) {
      return startDate
    } else { return `${startDate} - ${endDate}` }
  }

  get eventImages (): string[] | null {
    const images = this.eventModel?.Images?.map((i) => { return i.Normal }) ?? null
    return images
  }

  mounted (): void {
    this.getEvent()
  }

  externalLinkClicked (link: string): void {
    window.open(link, '_blank')
  }

  @Watch('$route')
  getEvent (): void {
    this.eventId = this.$route.params.slug
    Services.API.Events.GetEventBySlug(this.eventId)
      .then(returnEvent => {
        this.eventModel = returnEvent
      })
  }

  handlePrevPost (): void {
    Services.API.Events.PreviousEvent(this.eventId).then(returnEvent => {
      this.$router.push({
        name: 'Event Details',
        params: {
          eventId: returnEvent.EventId,
          slug: returnEvent.Slug
        }
      })
    })
  }

  handleNextPost (): void {
    Services.API.Events.NextEvent(this.eventId).then(returnEvent => {
      this.eventModel = returnEvent
      this.$router.push({
        name: 'Event Details',
        params: {
          eventId: returnEvent.EventId,
          slug: returnEvent.Slug
        }
      })
    })
  }
}
